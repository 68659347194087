import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

import {
  ECaseConfigService, EcaseHttpService, ECaseNavigationModel, ECaseNavigationService, ECaseSnackBarService,
  ECaseSplashScreenService,
  ECaseTranslationLoaderService, ECaseUtils, ErrorCodesService, LanguageService, LoginAuthenticationService,
  MatProgressBarService, ParametersService, PendingChangesGuard, RefreshContentTabsService, TitleService
} from 'synto-common';
import {DOCUMENT} from '@angular/common';
import {Observable} from 'rxjs/internal/Observable';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  environment;
  isShowEnvironment = true;
  showLogIn = false;
  logoSrc = '';
  isLanguagesLoaded = false;
  isBackendDown = false;
  isUserMasquerading = false;
  masqueradedUserProfile: any = {};
  originalUserProfile: any = {};
  ssoUserId;


  constructor(
    private translate: TranslateService,
    private router: Router,
    private pendingChangesGuard: PendingChangesGuard,
    private eCaseConfig: ECaseConfigService,
    private matProgressBarService: MatProgressBarService,
    private eCaseSnackBarService: ECaseSnackBarService,
    private eCaseSplashScreenService: ECaseSplashScreenService,
    private loginAuthenticationService: LoginAuthenticationService,
    private translationLoader: ECaseTranslationLoaderService,
    private eCaseNavigationService: ECaseNavigationService,
    private ecaseHttpService: EcaseHttpService,
    private parametersService: ParametersService,
    private titleService: TitleService,
    private languageService: LanguageService,
    private errorCodesService: ErrorCodesService,
    private refreshContentTabsService: RefreshContentTabsService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.pendingChangesGuard.isPristine;
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.ecaseHttpService.cancelDownloadSubjects.length > 0) {
      $event.returnValue = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.downloadingrefreshwarn');
    } else if (!this.canDeactivate()) {
      $event.returnValue = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.unsavedchangeswarning');
    }
  }

  // Added as per requirement for https://jira.evision.ca/browse/SYNTODEV-2635
  // Disable numerical value change in input number type field on mouse wheel scroll
  @HostListener('mousewheel', ['$event'])
  onMouseWheelScroll(event: WheelEvent): void {
    if (document.activeElement.tagName === 'INPUT' && document.activeElement.hasAttribute('type') && document.activeElement.getAttribute('type') === 'number') {
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    const url = window.location.pathname;
    const windowId = url.substring(url.length - 5, url.length);
    if (!isNaN(Number(windowId))) {
      this.ecaseHttpService.windowId = windowId;
    } else {
      this.ecaseHttpService.windowId = Math.floor(Math.random() * (100000 - 9999) + 9999);
    }
    // Set the navigation translations*/
    this.translationLoader.getSysFeatureVersions().subscribe({
      next: (response: any) => {
        if (response.status) {
          this.translationLoader.onStartLoadedFeatureVersion = response.version;
          this.translationLoader.loadTranslationsWithObservable(27).subscribe(() => {
            this.languageService.getAllLanguages().subscribe((langs: any[]) => {
              if (langs && langs.length > 0) {
                this.languageService.languageList = langs;
                this.languageService.triggerWhenLanguagesAreLoaded();
                this.translate.addLangs(langs.map(item => item.code));
                this.languageService.defaultSystemLanguage = langs.filter(item => item.sortingKey === 1)[0];
                this.translate.setDefaultLang(this.languageService.defaultSystemLanguage.code);
                this.translate.use(this.languageService.defaultSystemLanguage.code);
                this.document.documentElement.lang = this.languageService.defaultSystemLanguage.code;
                this.titleService.setTitleWithSeparator(['ecase.common.defaultBrowserTabTitle']);
                this.isLanguagesLoaded = true;
              } else {
                this.eCaseSnackBarService.show('failure', 'ecase.common.errorloadinglanguages');
              }
            });
          });
          this.parametersService.getParameterValues([
            '/core/ui/theme_color/name',
            '/core/environment',
            '/core/login/show_internal_login/enabled',
            '/core/ui/applicant_space/dashboard/quick_access_grid/enabled',
            '/core/ui/applicant_space/dashboard/product_documentation/enabled',
            '/core/ui/applicant_space/dashboard/my_cv/enabled',
            '/core/ui/applicant_space/dashboard/documents_grid/enabled',
            '/core/login/base_redirect/enabled',
            '/core/base_redirect/url',
            '/core/show_language_switcher/enabled',
            '/core/show_legend/enabled',
            '/core/ui/max_search_results_number',
            '/core/ui/mat_table_paginator_size/options',
            '/core/ui/default_date/format',
            '/core/ui/default_date_time_with_hour_and_minute/format',
            '/core/ui/default_date_time_with_timezone/format',
            '/core/ui/mat_table_paginator_default_size',
            '/core/ui/organisation_logo/url',
            '/core/ui/favicon_logo/url',
            '/core/ui/strong_password_validation/enabled',
            '/core/ui/applicant_space/show_paa_tables/enabled',
            '/core/ui/update_contact/show_dob_date_picker/enabled',
            '/core/ui/update_contact/show_additional_address_details/enabled',
            '/core/ui/update_contact/minimum_phone_number_length',
            '/core/ui/update_contact/show_area_code/enabled',
            '/core/review/xrai_base/url',
            '/core/ui/update_contact/show_civil_id/enabled',
            '/core/session/timeout/duration',
            '/core/ui/session_renew_timer/duration',
            '/core/ui/eligibility/project_overview/edit_application_internal_id/enabled',
            '/core/ui/applicant_space/dashboard/my_application_by_stage_grid/enabled',
            '/core/ui/applicant_space/dashboard/my_application_by_role_grid/enabled',
            '/core/ui/project_level_for_new_application/enabled',
            '/core/ui/admin_project_search/concatenate_status_and_stage/enabled',
            '/core/ui/account_creation/enabled',
            '/core/ui/hosting_organisation_logo_drop_shadow/enabled',
            '/core/security/two_factor_authentication/enabled',
            '/core/strong_password_validator_regex_pattern',
            '/core/ui/strong_password_help_text',
            '/core/ui/show_cookie_policy_pop_up/enabled',
            '/core/ui/cookie_policy_pop_up_content',
            '/core/ui/dashboard_pie_chart_slice_colors_hex_code',
            '/core/ui/account_creation/url/enabled',
            '/core/ui/default_currency',
            '/core/ui/format_negative_number_with_brackets',
            '/core/ui/opportunity_screen_date_time/format',
            '/core/ui/opportunity_screen_date_time/show_time_zone/enabled',
            '/core/ui/opportunity_screen/show_custom_prospectus_url/enabled',
            '/core/ui/opportunity_screen/custom_prospectus_url',
            '/core/forms/upload_max_size_in_mb',
            '/core/ged/global_file_size_in_mb',
            '/core/ui/organisation_logo/multilingual_logo/enabled',
            '/core/ui/specify_other_for_affiliation/enabled',
            '/core/ui/default_country_id',
            '/core/ui/default_organization_legal_status_id',
            '/core/ui/applicant_portal_date/show_date_format/enabled',
            '/core/ui/applicant_view_all_tasks_date/show_date_format/enabled',
            '/core/ui/form/validate_on_save/enabled',
            '/core/ui/account_mandatory_fields_applicant_message',
            '/core/show_dark_mode_switcher/enabled',
            '/core/my_profile/show_nationality',
            '/core/my_profile/show_given_name',
            '/core/my_profile/show_date_of_birth',
            '/core/my_profile/show_websites',
            '/core/my_profile/phone_number_mandatory',
            '/core/ui/eligibility_search_screen/show_application_id_internal_id/enabled',
            '/core/ui/crm_person/enable_payment_section',
            '/core/ui/crm_organization/enable_payment_section',
            '/core/email/client_support_email',
            '/core/ui/login_profile/other_position_term/enabled',
            '/core/ui/opportunity_screen_title_tooltip',
            '/core/ui/login_profile/other_position_term/enabled',
            '/core/my_profile/show_payment_method',
            '/core/crm/identities_with_default_values',
            '/core/ui/show_cookie_policy_dialog/enabled',
            '/core/ui/organization_legal_status_other_selected_is_mandatory',
            '/core/ui/crm_organization/enable_classification_section',
            '/core/my_profile/show_iban_format',
            '/core/ui/project/semantical_extra_fields',
            '/core/security/activated_mfa_modes',
            '/core/crm/identities_to_hide_in_user_profile',
            '/core/crm/identities_to_hide_in_crm_person',
            '/core/crm/mandatory_payment_identitifiers',
            '/core/my_profile/show_help_text',
            '/core/my_profile/show_first_time_logged_in_help_text',
            '/core/my_profile/show_complementary_form_tab/enabled',
            '/core/my_profile/complementary_form_id',
            '/core/my_profile/is_profile_locked',
            '/core/my_profile/profile_default_tab',
            '/core/crm_organization/additional_information_form_id',
            '/core/my_profile/show_hide_dates_organization_position',
            '/core/ui/change_user_lang_on_ui_lang_change/enabled',
            '/core/ui/cv/is_upload_cv_option/enabled',
            '/core/eligibility/complementary_form/id',
            '/core/security/logging_websocket/enabled',
            '/core/ui/forgot_password_link/enabled',
            '/core/ui/applicant_portal_date_time/show_time_zone/enabled',
            '/core/ui/applicant_portal_application_general_information/show_principal_investigator/enabled',
            '/core/my_profile/reset_password_tab/enabled',
            '/core/ui/applicant_portal_application_general_information/show_award_name/enabled',
            '/core/review/xrai_base/secret',
            '/core/ui/opportunity/show_message_applicant_complete_profile/enabled',
            '/core/cpm_competition/complementary_form/id',
            '/core/login_page/contact_us',
            '/core/ui/show_general_directory_button',
            '/core/ui/eligibility/finance/authorization_classification_hide',
            '/core/ui/competition_finance_config/show_accounting_system_block/enabled',
            '/core/ui/competition_finance_config/show_distribution_of_payments_block/enabled',
            '/core/ui/applicant_portal_application_general_information/task_deadline_date/enabled',
            '/core/ui/show_esignature_column/enabled',
            '/core/ui/show_under_construction_warning',
            '/core/ui/privacy_notice/show_refuse_option',
            '/core/my_profile/is_api_organization_enabled',
            '/core/ui/crm/show_cedex_field',
            '/core/ui/crm/show_line3_field',
            '/core/ui/organization_hierarchy/enabled',
            '/core/project/finance/is_accounting_commitment_approval_enabled',
            '/core/project/finance/is_accounting_payment_approval_enabled',
            '/core/project/finance/is_tax_management_enabled',
            '/core/ui/admin_application_document_type/sorting_order',
            '/core/opportunity_criteria/enabled',
            '/core/opportunity_criteria_logic',
            '/core/review/grants/dashboard/groupby_panel_id',
            '/core/api/clarivate/enabled',
            '/core/ui/form/budget_expenditure_item_component/other_budget_item_text_area/enabled',
            '/core/review/admin-dashboard/committee-monitoring/review-meeting-status/displayed-columns',
            '/core/eligibility/form_revision/show',
            '/core/current_customer_config',
            '/core/ui/show_application_pi_applicant_space_list',
            '/core/ui/crm_person/enable_payment_section_for_admin',
            '/core/ui/applicant_portal_add_document_button/disabled',
            '/core/review/admin-dashboard/application-display/layout',
            '/core/my_profile/complementary_form/is_mandatory',
            '/core/my_profile/show_documents_tab/enabled',
            '/core/review/configuration/default_pa_report_review_form_id',
            '/core/cv/configuration/max_cv_non_submitted',
            '/core/ui/captcha_site_key'
          ]).subscribe({
              next: (_response: any) => {
                if (_response.status) {
                  this.setThemeOfApplication((_response.keyValues)['/core/ui/theme_color/name']);
                  console.log(_response);
                  this.environment = (_response.keyValues)['/core/environment'];
                  this.isShowEnvironment = this.environment !== 'PROD';
                  this.showLogIn = (_response.keyValues)['/core/environment'] === 'DEV';
                  const isBaseRedirect = (_response.keyValues)['/core/login/base_redirect/enabled'] === 'true';
                  const baseRedirectUrl = (_response.keyValues)['/core/base_redirect/url'];
                  this.parametersService.setParameters(_response.keyValues);
                  this.logoSrc = this.parametersService.parameter['/core/ui/favicon_logo/url'];
                  this.ecaseHttpService.sessionTimeoutLimitForShowingMessage = Number(this.parametersService.parameter['/core/ui/session_renew_timer/duration']);
                  this.ecaseHttpService.sessionTimeoutLimitBeforeExpiration = Number(this.parametersService.parameter['/core/session/timeout/duration'])
                    - this.ecaseHttpService.sessionTimeoutLimitForShowingMessage;
                  this.eCaseSplashScreenService.hide();
                  this.loginAuthenticationService.checkIfUserIsLoggedIn().subscribe((innerResponse: any) => {
                    if (innerResponse.status) {
                      console.log(innerResponse);
                      console.log('made it here_1');
                      this.loginAuthenticationService.parameters = this.parametersService.parameter;
                      this.loginAuthenticationService.syntoDateFormat = this.parametersService.parameter['/core/ui/default_date/format'];
                      if (this.loginAuthenticationService.urlArray && this.loginAuthenticationService.urlArray.length === 1) {
                        console.log('made it here_2');
                        // this.router.navigate([this.loginAuthenticationService.urlArray[0]]);
                      } else {
                        console.log('made it here_3');
                        this.loginAuthenticationService.isUserLoggedIn = true;
                        this.loginAuthenticationService.userProfile = innerResponse.userProfile;
                        this.ssoUserId = this.loginAuthenticationService.userProfile.id;
                        this.isUserMasquerading = innerResponse.isUserMasquerading;
                        this.masqueradedUserProfile = innerResponse.userProfile;
                        this.originalUserProfile = innerResponse.originalUserProfile;
                        this.loginAuthenticationService.primeRole = innerResponse.primeRole;
                        this.loginAuthenticationService.dummyRole = innerResponse.primeRole;
                        this.loginAuthenticationService.roles = innerResponse.roles;
                        this.loginAuthenticationService.featureIdList = innerResponse.featureIdList;
                        this.loginAuthenticationService.sysActionIdList = innerResponse.sysActionIdList;
                        this.loginAuthenticationService.tabs = innerResponse.tabs;
                        this.eCaseNavigationService.sideNavTabs = innerResponse.tabs;
                        this.eCaseNavigationService.setNavigationModel(new ECaseNavigationModel(this.loginAuthenticationService
                          .addDashBoards(this.loginAuthenticationService.tabs[0])));
                        if (this.languageService.defaultSystemLanguage) {
                          this.translate.use(this.languageService.defaultSystemLanguage.code);
                          this.document.documentElement.lang = this.languageService.defaultSystemLanguage.code;
                          this.translate.setDefaultLang(this.languageService.defaultSystemLanguage.code);
                        }
                        // this.translationLoader.loadTranslations(27);
                        // console.log((response.tabs.filter(e => e.roleId === response.primeRole)[0]).url);
                        // this.router.navigate([this.loginAuthenticationService.urlArray[0]]);
                        // this.router.navigate([(response.tabs.filter(e => e.roleId === response.primeRole)[0]).url]);
                      }
                      if (isBaseRedirect) {
                        window.location.href = baseRedirectUrl;
                      } else {
                        this.showLogIn = true;
                      }
                    } else {
                      if (isBaseRedirect) {
                        window.location.href = baseRedirectUrl;
                      } else {
                        this.showLogIn = true;
                      }
                    }
                  });
                  console.log('SAMEE', this.loginAuthenticationService);
                } else {
                  this.eCaseSnackBarService.show('failure', 'Services not up');
                }
                // this.titleService.setFavicon('assets/images/favicons/kfasFavicon.ico');
                this.titleService.setFavicon(this.logoSrc);
              },
              error: () => {
                this.setThemeOfApplication(null);

                this.environment = 'DEV';
                this.showLogIn = true;
                const isBaseRedirect = false;
                const baseRedirectUrl = '/secure';



                this.loginAuthenticationService.checkIfUserIsLoggedIn().subscribe((innerResponse: any) => {
                  if (innerResponse.status) {
                    console.log(innerResponse);

                    console.log('made it here_1');
                    this.loginAuthenticationService.parameters = this.parametersService.parameter;
                    // this.loginAuthenticationService.paginatorPageSizeOption=this.parametersService.parameter['/core/ui/mat_table_paginator_size/options'].split(',');
                    this.loginAuthenticationService.syntoDateFormat = this.parametersService.parameter['/core/ui/default_date/format'];
                    if (this.loginAuthenticationService.urlArray && this.loginAuthenticationService.urlArray.length === 1) {
                      console.log('made it here_2');
                      // this.router.navigate([this.loginAuthenticationService.urlArray[0]]);
                    } else {
                      console.log('made it here_3');
                      this.loginAuthenticationService.isUserLoggedIn = true;
                      this.loginAuthenticationService.userProfile = innerResponse.userProfile;
                      this.isUserMasquerading = innerResponse.isUserMasquerading;
                      this.masqueradedUserProfile = innerResponse.userProfile;
                      this.originalUserProfile = innerResponse.originalUserProfile;
                      this.loginAuthenticationService.primeRole = innerResponse.primeRole;
                      this.loginAuthenticationService.dummyRole = innerResponse.primeRole;
                      this.loginAuthenticationService.roles = innerResponse.roles;
                      this.loginAuthenticationService.featureIdList = innerResponse.featureIdList;
                      this.loginAuthenticationService.sysActionIdList = innerResponse.sysActionIdList;
                      this.loginAuthenticationService.tabs = innerResponse.tabs;
                      this.eCaseNavigationService.sideNavTabs = innerResponse.tabs;
                      this.eCaseNavigationService.setNavigationModel(new ECaseNavigationModel(this.loginAuthenticationService
                        .addDashBoards(this.loginAuthenticationService.tabs[0])));
                      this.translate.use(this.languageService.defaultSystemLanguage.code);
                      this.document.documentElement.lang = this.languageService.defaultSystemLanguage.code;
                      this.translate.setDefaultLang(this.languageService.defaultSystemLanguage.code);

                    }
                    if (isBaseRedirect) {
                      window.location.href = baseRedirectUrl;
                    } else {
                      this.showLogIn = true;
                    }
                  } else {
                    if (isBaseRedirect) {
                      window.location.href = baseRedirectUrl;
                    } else {
                      this.showLogIn = true;
                    }
                  }
                });
                console.log('SAMEE', this.loginAuthenticationService);
              }
            }
          );
        } else {
          this.eCaseSnackBarService.show('failure', response.errorMessage);
        }
      },
      error: (e) => {
        try{
          const error = JSON.parse(e.message);
          this.errorCodesService.errorCode = error.errorCode;
          console.error(error.errorMessage);
        }catch(_e){
          console.error(_e);
        }

        this.isBackendDown = true;
    }
    });
    this.loginAuthenticationService.onMasqueradeChange.subscribe((event: any) => {
      this.isUserMasquerading = event.isUserMasquerading;
      this.masqueradedUserProfile = event.masqueradedUserProfile;
      this.originalUserProfile = event.originalUserProfile;
    });
  }

  endMasquerading(): void {
    this.loginAuthenticationService.endUserMasquerading().subscribe((result: any) => {
      if (result.hasOwnProperty('status') && !result.status) {
        this.eCaseSnackBarService.show('failure');
      } else {
        this.eCaseSnackBarService.show('success');
        this.isUserMasquerading = false;
        this.masqueradedUserProfile = {};
        this.originalUserProfile = {};
        this.refreshContentTabsService.getContentTabs(result.roles, result.primeRole).subscribe((tabData) => {
          this.router.navigate([tabData.firstClickableFeature.url]).then(() => {
            location.reload();
          });
        });
      }
    });
  }

  setThemeOfApplication(color): void {
    if (!color) {
      color = 'mat-eCase-dark-700-bg';
    }
    this.eCaseConfig.setSettings({
      'layout': {
        'navigation': 'left',
        'navigationFolded': false,
        'toolbar': 'below',
        'footer': 'below',
        'mode': 'fullwidth'
      },
      'colorClasses': {
        'accent': color,
        'toolbar': 'mat-white-500-bg',
        'navbar': 'mat-blue-700-bg',
        'footer': 'mat-eCase-dark-900-bg'
      },
      'customScrollbars': true,
      'routerAnimation': 'fadeIn'
    });
  }
}
